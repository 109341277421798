import React from 'react';


function ProfileSection() {
  return (

    <div className="main-content">
      {/* <!-- Header --> */}
      <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style={{ minheight: "600px", backgroundimage: 'url(https://wallpaperaccess.com/full/5838290.jpg)', backgroundsize: 'cover', backgroundposition: 'center top' }}>
        {/* <!-- Mask --> */}
        <span className="mask bg-gradient-default opacity-8"></span>
        {/* <!-- Header container --> */}
      </div>
      {/* <!-- Page content --> */}
      <div className="container mt--7">
        <div className="row">
          <div className="col-xl-12 order-xl-1">
            <div className="card bg-secondary shadow">
              <div className="card-header bg-white border-0">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h3 className="mb-0">My account</h3>
                  </div>
                  <div className="col-4 text-right">
                    <a href="#!" className="btn btn-sm btn-primary">Settings</a>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {/* <form> */}
                <h6 className="heading-small text-muted mb-4">User information</h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label" for="input-first-name">First Name</label>
                        <input type="text" id="input-first-name" className="form-control form-control-alternative" placeholder="First Name" value="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label" for="input-last-name">Last Name</label>
                        <input type="text" id="input-last-name" className="form-control form-control-alternative" placeholder="Last Name" value="" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label" for="">E-mail Address </label>
                        <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="Mail-address"  value="finacleAI@info.com" disabled />
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label" for="input-last-name">Last name</label>
                        <input type="text" id="input-last-name" className="form-control form-control-alternative" placeholder="Last name" value="" />
                      </div>
                    </div> */}
                  </div>
                </div>
                <hr className="my-4" />
                {/* <hr className="my-4" /> */}
                {/* <!-- Description --> */}
                {/* <h6 className="heading-small text-muted mb-4">Settings</h6> */}


                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 order-xl-1">
                      <div className="card bg-secondary shadow">
                        <div className="card-header bg-white border-0">
                          <div className="row align-items-center">
                            <div className="col-8">
                              {/* <h3 className="mb-0">My account</h3> */}
                            </div>
                            <div className="col-4 text-right">
                              <a href="#!" className="btn btn-sm btn-primary">Save setting</a>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {/* <form> */}
                          <h6 className="heading-small text-muted mb-4">Password reset</h6>
                          <div className="pl-lg-4">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group focused">
                                  <label className="form-control-label" for="input-first-name">Password</label>
                                  <input type="text" id="input-first-name" className="form-control form-control-alternative" placeholder="Enter new Password" value="" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group focused">
                                  <label className="form-control-label" for="input-last-name">Confirm Password</label>
                                  <input type="text" id="input-last-name" className="form-control form-control-alternative" placeholder="Re-Enter new Password" value="" />
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group focused">
                                  <label className="form-control-label" for="input-first-name">First name</label>
                                  <input type="text" id="input-first-name" className="form-control form-control-alternative" placeholder="First name" value="" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group focused">
                                  <label className="form-control-label" for="input-last-name">Last name</label>
                                  <input type="text" id="input-last-name" className="form-control form-control-alternative" placeholder="Last name" value="" />
                                </div>
                              </div>
                            </div> */}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ProfileSection;