import React, { Component } from 'react';
import Header from './header';
import API from '../config/api';
import { API_URL } from '../config/const';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from 'react-bootstrap/Dropdown';
import { green, pink, red } from '@mui/material/colors';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import highstock from 'highcharts/highstock';


var seriesOptions = [],
  seriesCounter = 0,
  names = ['open', 'forecast'];

/**
* Create the chart when all data is loaded
* @return {undefined}
*/
function createChart() {

  highstock.stockChart('container', {

    rangeSelector: {
      selected: 4
    },
    xAxis: {
      type: "datetime",
      
    },

    yAxis: {
      title: {
        text: "Price"
      },
      // labels: {
      //     formatter: function () {
      //       console.log(this.value)
      //         return (this.value > 0 ? ' + ' : '') + this.value + '%';
      //     }
      // },
      // plotLines: [{
      //     value: 0,
      //     width: 2,
      //     color: 'silver'
      // }]
    },

    // plotOptions: {
    //     series: {
    //         compare: 'percent',
    //         showInNavigator: true
    //     }
    // },

    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
      valueDecimals: 2,
      split: true
    },

    series: seriesOptions
  });
}

function success(data, name) {
  console.log(data)
  var i = names.indexOf(name);
  seriesOptions[i] = {
    name: name,
    data: data
  };

  console.log("seriesOptions", seriesOptions)

  // As we're loading the data asynchronously, we don't know what order it
  // will arrive. So we keep a counter and create the chart when all the data is loaded.
  seriesCounter += 1;

  if (seriesCounter === names.length) {
    createChart();
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343a40",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
class UStreasury extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }
  async componentDidMount() {
    this.setState({
      data: await API({ url: API_URL + '/stock/api/USTreasuryView/', method: {} })
    })

    API({ url: API_URL + '/stock/api/Ustreasury/', method: {} }).then((data) => success(data, "open"))
    API({ url: API_URL + '/stock/api/Ustreasury2/', method: {} }).then((data) => success(data, "forecast"))
  }
  render() {
    const array = ["Buy", "Sell"]
    return (
      <div>
        <Header />
        <Container>
          <h1>US Treasury</h1>
          <Row>
            <Col>
              <div id="container" style={{ height: "400" }}></div>
            </Col>
            <Col>
              <TableContainer style={{ width: "100%" }} component={Card}>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date & Time</StyledTableCell>
                      <StyledTableCell>Symbol</StyledTableCell>
                      <StyledTableCell>Open</StyledTableCell>
                      <StyledTableCell>Open (Forecast)</StyledTableCell>
                      <StyledTableCell>Buy / Sell</StyledTableCell>
                      <StyledTableCell>Profit</StyledTableCell>
                      <StyledTableCell>Close</StyledTableCell>
                      <StyledTableCell>B/S QTY</StyledTableCell>
                      <StyledTableCell>Total Inventory</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>
                          {new Date(row.date).toLocaleDateString()} {new Date(row.date).toLocaleTimeString()}
                        </TableCell>
                        <TableCell >
                          {row.symbol}
                        </TableCell>
                        <TableCell>{row.open_value}</TableCell>
                        <TableCell>{row.open_forecast}</TableCell>
                        <TableCell>{row.buy_or_sell}</TableCell>
                        <TableCell>{row.profit}</TableCell>
                        <TableCell>{row.close}</TableCell>
                        <TableCell>{row.buy_or_sell_qty}</TableCell>
                        <TableCell>{row.tot_inventory}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row >

        </Container >
      </div>
    );
  }
}

export default UStreasury;
