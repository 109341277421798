import React from 'react';
import { useNavigate, } from 'react-router-dom';
import { Row, Col, Button, Alert } from 'react-bootstrap';

import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { API_URL } from '../config/const';
import useScriptRef from '../hooks/useScriptRef';

const RestRegister = ({ className, ...rest }) => {
    let navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const Schema = Yup.object().shape({
        password: Yup.string().required("This field is required")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Use 8 or more characters with mix of letters,numbers & symbols"
            ),
        // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
        confirmpassword: Yup.string().required("This field is required").when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
            )
        })
    });
    return (

        <div className="limiter">
            <div className="container-login100" style={{ backgroundimage: 'url()' }}>
                <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                    <div className="row">

                        <div style={{ alignItems: "center", justifyContent: "center", display: "flex", flex: 1 }} >
                            <img style={{
                                width: "auto",
                                height: "auto"
                            }} src="https://speedy.uenicdn.com/0156aa25-41cc-425c-ae2e-ce724755af97/s300_80a/image/upload/v1658769693/business/853cf106-b560-4298-ac2a-e0c16564fd88.png" alt="" />
                        </div>
                    </div>
                    <span className="login100-form-title p-b-49">
                        Signup
                    </span>

                    <React.Fragment>
                        <Formik
                            initialValues={{
                                username: '',
                                email: '',
                                password: '',
                                confirmpassword: "",
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                // username: Yup.string().required('Username is required'),
                                password: Yup.string().required("This field is required")
                                    .matches(
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                        "Use 8 or more characters with mix of letters,numbers & symbols"
                                    ),
                                // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
                                confirmpassword: Yup.string().required("This field is required").when("password", {
                                    is: val => (val && val.length > 0 ? true : false),
                                    then: Yup.string().oneOf(
                                        [Yup.ref("password")],
                                        "Both password need to be the same"
                                    )
                                })
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    axios
                                        .post(API_URL + '/web/api/users/register', {
                                            username: values.email,
                                            password: values.password,
                                            email: values.email
                                        })
                                        .then(function (response) {
                                            if (response.data.success) {
                                                navigate('/login');
                                            } else {
                                                setStatus({ success: false });
                                                setErrors({ submit: response.data.msg });
                                                setSubmitting(false);
                                            }
                                        })
                                        .catch(function (error) {
                                            setStatus({ success: false });
                                            setErrors({ submit: "Email already exists...!" });
                                            setSubmitting(false);
                                        });
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit} className={className} {...rest}>
                                    {/* <div className="form-group mb-3">
                                        <input
                                            className="form-control"
                                            error={touched.username && errors.username}
                                            label="Username"
                                            placeholder="Username"
                                            name="username"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.username}
                                        />
                                        {touched.username && errors.username && <small className="text-danger form-text">{errors.username}</small>}
                                    </div> */}
                                    <div className="form-group mb-3">
                                        <input
                                            className="form-control"
                                            error={touched.email && errors.email}
                                            label="Email Address"
                                            placeholder="Email Address"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.email}
                                        />
                                        {touched.email && errors.email && <small className="text-danger form-text">{errors.email}</small>}
                                    </div>
                                    <div className="form-group mb-4">
                                        <input
                                            className="form-control"
                                            error={touched.password && errors.password}
                                            label="Password"
                                            placeholder="Password"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="password"
                                            value={values.password}
                                        />
                                        {touched.password && errors.password && <small className="text-danger form-text">{errors.password}</small>}
                                    </div>

                                    <div className="form-group mb-4">
                                        <input
                                            className="form-control"
                                            error={touched.password && errors.password}
                                            label="Confirm Password"
                                            placeholder="Confirm Password"
                                            name="confirmpassword"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="password"
                                            value={values.confirmpassword}
                                        />
                                        {touched.confirmpassword && errors.confirmpassword && <small className="text-danger form-text">{errors.confirmpassword}</small>}
                                    </div>

                                    {errors.submit && (
                                        <Col sm={12}>
                                            <Alert variant="danger">{errors.submit}</Alert>
                                        </Col>
                                    )}

                                    <div className="custom-control custom-checkbox  text-left mb-4 mt-2">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label" htmlFor="customCheck1">
                                            Save credentials.
                                        </label>
                                    </div>

                                    <Row>
                                        <Col mt={2}>
                                            <Button
                                                className="btn-block"
                                                color="primary"
                                                disabled={isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="primary"
                                            >
                                                Sign UP
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            )}
                        </Formik>
                        <div className="flex-col-c p-t-40" style={{ textdecoration: 'underline' }}>
                            <span className="txt1 p-b-17 text-center">
                                Did you have an account?<br /><u> <a href="/login"> Login Here !</a></u>
                            </span>

                            {/* <a href="#" className="txt2">
                                Sign Up
                            </a> */}
                        </div>
                        <hr />

                    </React.Fragment>

                </div>
            </div>
        </div>

    );
};

export default RestRegister;
