import React, { Component } from 'react';
import API from '../config/api';
import { API_URL } from '../config/const';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from 'react-bootstrap/Dropdown';
import { green, pink, red } from '@mui/material/colors';
import Header from './header';
import Container from 'react-bootstrap/esm/Container';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#343a40",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
class PortfolioBuilder extends Component {
    constructor(props){
        super(props)
        this.state={
          data:[]
        }
    }
    async componentDidMount(){
      this.setState({
        data:await API({url:API_URL+'/stock/api/PortfolioView/',method:{}})
      })
    }
  render() {
    const array = ["Low","Medium","High"]
    return (
      <>
        <Header />
        <Container>
            <h1>Portfolio builder</h1>
            <TableContainer  style={{ width:"100%" }}  component={Card}>
            <Table  aria-label="simple table">
                <TableHead>
                <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Symbol</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Volatility</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {this.state.data?.map((row) => (
                    <TableRow
                    key={row.symbol}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                    {new Date(row.date).toLocaleDateString()} {new Date(row.date).toLocaleTimeString()}
                  </TableCell>
                    <TableCell>
                    {row.symbol}
                    </TableCell>
                    <TableCell >
                        {row.name}
                    </TableCell>
                    <TableCell>{row.volatility}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Container>
      </>
    );
  }
}

export default PortfolioBuilder;
