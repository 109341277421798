import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { green, pink, red } from '@mui/material/colors';
export const upArrow = () => (
    <ArrowDropUpIcon sx={{ color: green[500] }} />
)

export const downArrow = () => (
    <ArrowDropDownIcon sx={{ color: red[500] }} />
)
