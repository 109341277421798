import React, { useEffect } from 'react';
// import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Counter } from './features/counter/Counter';
import './App.css';
import LoginPage from './components/login';
import SignupPage from './components/signup';
import ProfileSection from './components/profile';
import Home from './components/stockScanner';
import SentimentTracker from './components/sentimentTracker';
import PortfolioBuilder from './components/PortfolioBuilder';
import SpyTrader from './components/SpyTrader';
import UStreasury from './components/UStreasury';
import EuroUsd from './components/EuroUSd';
import BTCUSD from './components/BTCUSD';
import AuthGuard from './AuthGuard';
import { connect } from 'react-redux';
import { ACCOUNT_INITIALIZE } from './store/actions';
import API from './config/api';
import { API_URL } from './config/const';
import GuestGuard from './GuestGuard';

function App(props) {

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = async () => {
    console.log(props)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", props.account.token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    // fetch("http://127.0.0.1:8000/web/bikes/", requestOptions)
    // .then(response => response.text())
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
    let json = await (await fetch(API_URL + '/web/api/users/checkSession',requestOptions)).json()
        
        console.log(json);

    if ((json.success === 'False') || !(json.success === true)) {
      props.loginSession()
    }
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signup" element={
          <GuestGuard>
            <SignupPage />
          </GuestGuard>
        } />
        <Route path="/login" element={
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        } />
        <Route path="/" element={
          <AuthGuard>
            <Home title="Stock Scanner" />
          </AuthGuard>
        } />
        <Route path="/SentimentTracker" element={
          <AuthGuard>
            <SentimentTracker title="Sentiment Tracker" />
          </AuthGuard>
        } />
        <Route path="/GSPC" element={<AuthGuard>
          <SpyTrader title="Spy Trader" />
        </AuthGuard>} />
        <Route path="/PortfolioBuilder" element={<AuthGuard>
          <PortfolioBuilder />
        </AuthGuard>} />
        <Route path="/UStreasury" element={<AuthGuard>
          <UStreasury />
        </AuthGuard>} />
        <Route path="/EUR" element={<AuthGuard>
          <EuroUsd />
        </AuthGuard>} />
        <Route path="/BTC" element={<AuthGuard>
          <BTCUSD />
        </AuthGuard>} />
        {/* <Route path="/narwhal">
            <Home />
          </Route>
          <Route path="/whale">
            <Home />
          </Route> */}
      </Routes>
    </BrowserRouter>
  );
}


const mapStateToProps = (state) => ({
  account: state.account
});
const mapDispatchToProps = (dispatch) => ({
  loginSession: () => dispatch({
    type: ACCOUNT_INITIALIZE,
    payload: { isLoggedIn: false, user: '', token: '' }
  })

});
export default connect(mapStateToProps, mapDispatchToProps)(App);
