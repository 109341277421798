import React, { Component } from 'react';
import SummaryTable from './summaryTable';
import Header from './header';
import API from '../config/api';
import { API_URL } from '../config/const';
import Container from 'react-bootstrap/esm/Container';
class Home extends Component {
    constructor(props){
        super(props)
        this.state={
          data:[]
        }
    }
    async componentDidMount(){
      this.setState({
        data:await API({url:API_URL+'/stock/api/summary/',method:{}})
      })
    }

     onDateChange = async(event) => {
      event.preventDefault()
      const data = await API({url:API_URL+'/stock/api/summary/?date='+event.target.value,method:{}})
      this.setState({
        data
      })
    }
  render() {
    return (
      <div> 
        <Header />
        <Container>
            <h1>{this.props.title}</h1>
            <SummaryTable onDateChange={this.onDateChange} data={this.state.data} />
        </Container>
        </div>
            
    );
  }
}

export default Home;
