import { API_URL } from "./const";

export default async function API(props){
    try {
        const response = await fetch(props.url, props.options);
        const res = await response.json();
        return res;
    } catch (error) {
        return error;
    }
}