import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../config/const';
import { LOGOUT } from '../store/actions';


function Header() {
  const account = useSelector((state) => state.account);
  const dispatcher = useDispatch();
  const handleLogout = () => {
    axios
        .post(API_URL + '/web/api/users/logout', {}, { headers: { Authorization: `${account.token}` } })
        .then(function (response) {
          console.log(response)
            // Force the LOGOUT
            //if (response.data.success) {
            dispatcher({ type: LOGOUT });
            //} else {
            //    console.log('response - ', response.data.msg);
            //}
        })
        .catch(function (error) {
            console.log('error - ', error);
        });
};

  return (
      <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <a class="navbar-brand" href="/StockScanner">
                <img src="https://speedy.uenicdn.com/0156aa25-41cc-425c-ae2e-ce724755af97/s300_80a/image/upload/v1658769693/business/853cf106-b560-4298-ac2a-e0c16564fd88.png" alt="" width="100" height="40" />
            </a></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='flex-column align-items-end'>
          <Nav className="me-auto">
            <Nav.Link href="/">Stock Scanner</Nav.Link>
            <Nav.Link href="/PortfolioBuilder">Portfolio Builder</Nav.Link>
            <Nav.Link href="/SentimentTracker">Sentiment Tracker</Nav.Link>
            <Nav.Link href="/GSPC">GSPC.INDX</Nav.Link>
            <Nav.Link href="/UStreasury">US Treasury</Nav.Link>
            <Nav.Link href="/EUR">EUR.FOREX</Nav.Link>
            <Nav.Link href="/BTC">BTC.USD</Nav.Link>
            <Nav.Link to="#" className="dud-logout" onClick={handleLogout} title="Logout">
                                    Logout
                                </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;