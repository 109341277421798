import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from 'react-bootstrap/Dropdown';
import { green, pink, red } from '@mui/material/colors';
import Container from 'react-bootstrap/esm/Container';
import { formatDate } from '../config/utils';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function createData(name, calories, fat) {
  return { name, calories, fat };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#343a40",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const rows = [
  createData('Frozen yoghurt', 159, true),
  createData('Ice cream sandwich', 237, false),
  createData('Eclair', 262, true),
  createData('Cupcake', 305, true),
  createData('Gingerbread', 12, false),
];

const upArrow = () => (
  <ArrowDropUpIcon sx={{ color: green[500] }} />
)

const downArrow = () => (
  <ArrowDropDownIcon sx={{ color: red[500] }} />
)
export default function SummaryTable(props) {
  const [date,setDate] = React.useState(formatDate(new Date()))
  const [bullish,setBullish] = React.useState([])
  const [bearish,setBearish] = React.useState([])
  React.useEffect(()=>{
    let bull = []
    let bear = []
    props.data.map((data)=>{
      if(data.trend === "bull"){
        bull.push(data)
      }
      else{
        bear.push(data)
      }
    })
    setBullish(bull)
    setBearish(bear)
  },[props.data,date])
  const onChange = (event) => {
    setDate(event.target.value)
    props.onDateChange(event)
  }
  return (
      <Container>
        <Dropdown style={{ marginBottom: 10 ,padding:10, }}>
          {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
            09/09/2022
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#">02/09/2022</Dropdown.Item>
            <Dropdown.Item href="#">26/08/2022</Dropdown.Item>
            <Dropdown.Item href="#">19/08/2022</Dropdown.Item>
          </Dropdown.Menu> */}
          <input type={"date"} style={{color:"green"}} value={date} onChange={onChange} />
        </Dropdown>
        <Row>
          <Col md={6}>
            <h1 style={{color:"green"}}>Bullish</h1>
          <TableContainer component={Card}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date & Time</StyledTableCell>
                <StyledTableCell>Symbol</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                
                <StyledTableCell>Perfomance Percentage</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bullish?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {new Date(row.created_at).toLocaleDateString()} {new Date(row.created_at).toLocaleTimeString()}
                  </TableCell>
                  <TableCell >
                    {row.symbol}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.performance_percent}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          </Col>
        
        <Col md={6}>
        <h1 style={{color:"red"}}>Bearish</h1>
        <TableContainer component={Card}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date & Time</StyledTableCell>
                <StyledTableCell>Symbol</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Perfomance Percentage</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bearish?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {new Date(row.created_at).toLocaleDateString()} {new Date(row.created_at).toLocaleTimeString()}
                  </TableCell>
                  <TableCell >
                    {row.symbol}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.performance_percent}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Col>
        
        </Row>
        
      </Container>
  );
}
