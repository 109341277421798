import React, { Component } from 'react';
import API from '../config/api';
import { API_URL } from '../config/const';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from 'react-bootstrap/Dropdown';
import { green, pink, red } from '@mui/material/colors';
import Header from './header';
import { downArrow, upArrow } from './Arrow';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#343a40",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
class sentimentTracker extends Component {
    constructor(props){
        super(props)
        this.state={
          data:[]
        }
    }
    async componentDidMount(){
      this.setState({
        data:await API({url:API_URL+'/stock/api/SentimentTrackerView/',method:{}})
      })
    }
  render() {
    return (
      <>
      <Header />
      <Container>
        <Row>
          <Col>
              <h1>Stocks</h1>
              <TableContainer  style={{ width:"100%" }}  component={Card}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                  <TableRow>
                  <StyledTableCell>Date & Time</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Outlook</StyledTableCell>
                      <StyledTableCell align="center">Change in sentiment</StyledTableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.data.stock?.map((row) => (
                      <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell>
                      {new Date(row.date).toLocaleDateString()} {new Date(row.date).toLocaleTimeString()}
                      </TableCell>
                      <TableCell >
                          {row.symbol}
                      </TableCell>
                      <TableCell align="center">{row.outlook === "bull" ? upArrow() : downArrow()}</TableCell>
                      <TableCell align="center">{row.recent_changes === "bull" ? upArrow() : downArrow()}</TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
              </TableContainer>
              </Col>

              <Col>
              <h1>Commodity</h1>
              <TableContainer  style={{ width:"100%" }}  component={Card}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                  <TableRow>
                  <StyledTableCell>Date & Time</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Outlook</StyledTableCell>
                      <StyledTableCell align="center">Change in sentiment</StyledTableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.data.comm?.map((row) => (
                      <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell>
                      {new Date(row.date).toLocaleDateString()} {new Date(row.date).toLocaleTimeString()}
                      </TableCell>
                      <TableCell >
                          {row.symbol}
                      </TableCell>
                      <TableCell align="center">{row.outlook === "bull" ? upArrow() : downArrow()}</TableCell>
                      <TableCell align="center">{row.recent_changes === "bull" ? upArrow() : downArrow()}</TableCell></TableRow>
                  ))}
                  </TableBody>
              </Table>
              </TableContainer>
              </Col>
          
        </Row>
      </Container>
      </>
    );
  }
}

export default sentimentTracker;
