import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Alert } from 'react-bootstrap';

import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import API from '../config/api';
import { API_URL } from '../config/const';
import useScriptRef from '../hooks/useScriptRef';
import { ACCOUNT_INITIALIZE } from '../store/actions';


function LoginPage({ className, ...rest }) {
    const dispatcher = useDispatch();
    const scriptedRef = useScriptRef();
    const navigate = useNavigate()


    return (

        <div className="limiter">
            <div className="container-login100" style={{ backgroundimage: 'url()' }}>
                <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                    <div className="row">

                        <div style={{ alignItems: "center", justifyContent: "center", display: "flex", flex: 1 }} >
                            <img style={{
                                width: "auto",
                                height: "auto"
                            }} src="https://speedy.uenicdn.com/0156aa25-41cc-425c-ae2e-ce724755af97/s300_80a/image/upload/v1658769693/business/853cf106-b560-4298-ac2a-e0c16564fd88.png" alt="" />
                        </div>
                    </div>
                    <span className="login100-form-title p-b-49">
                        Login
                    </span>

                    <React.Fragment>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                password: Yup.string().max(255).required('Password is required')
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    axios
                                        .post(API_URL + '/web/api/users/login', {
                                            password: values.password,
                                            email: values.email
                                        })
                                        .then(function (response) {
                                            if (response.data.success) {
                                                console.log(response.data);
                                                dispatcher({
                                                    type: ACCOUNT_INITIALIZE,
                                                    payload: { isLoggedIn: true, user: response.data.user, token: response.data.token }
                                                });
                                                if (scriptedRef.current) {
                                                    setStatus({ success: true });
                                                    setSubmitting(false);
                                                }
                                            } else {
                                                setStatus({ success: false });
                                                setErrors({ submit: response.data.msg });
                                                setSubmitting(false);
                                            }
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                            setStatus({ success: false });
                                            setErrors({ submit: error.response.data.msg });
                                            setSubmitting(false);
                                        });
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit} className={className} {...rest}>
                                    <div className="form-group mb-3">
                                        <input
                                            className="form-control"
                                            error={touched.email && errors.email}
                                            label="Email Address"
                                            placeholder="Email Address"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.email}
                                        />
                                        {touched.email && errors.email && <small className="text-danger form-text">{errors.email}</small>}
                                    </div>
                                    <div className="form-group mb-4">
                                        <input
                                            className="form-control"
                                            error={touched.password && errors.password}
                                            label="Password"
                                            placeholder="Password"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="password"
                                            value={values.password}
                                        />
                                        {touched.password && errors.password && <small className="text-danger form-text">{errors.password}</small>}
                                    </div>

                                    {errors.submit && (
                                        <Col sm={12}>
                                            <Alert variant="danger">{errors.submit}</Alert>
                                        </Col>
                                    )}

                                    <div className="custom-control custom-checkbox  text-left mb-4 mt-2">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label" htmlFor="customCheck1">
                                            Save credentials.
                                        </label>
                                    </div>

                                    <Row>
                                        <Col mt={2}>
                                            <Button
                                                className="btn-block"
                                                color="primary"
                                                disabled={isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="primary"
                                            >
                                                Sign IN
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            )}
                        </Formik>
                        <div className="flex-col-c p-t-40" style={{ textdecoration: 'underline' }}>
                            <span className="txt1 p-b-17 text-center">
                                Did you haven't an account?<br /><u> <a href="/signup"> Signup Here !</a></u>
                            </span>

                            {/* <a href="#" className="txt2">
                                Sign Up
                            </a> */}
                        </div>
                        <hr />
                        
                    </React.Fragment>
                </div>
            </div>
        </div>


    );
}

export default LoginPage;
